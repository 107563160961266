import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";
import enLocale from "./en.json";
import enCom from "@tt/i18n/src/en";
import inLocale from "./idn.json";
import trLocale from "./tr";
import trCom from "@tt/i18n/src/tr";
import vi from "./vi.json";
import ko from "./ko.json";
import ja from "./ja.json";
import th from "./th.json";
import verConfig from "@/utils/verConfig"
import ms from "./ms.json"
import store from '../store'

Vue.use(VueI18n);

const messages = {
  en: {
    ...enCom,
    ...enLocale,
  },
  // zh: {
  //   ...zhLocale,
  // },
  idn: {
    ...inLocale,
  },
  tr: {
    ...trCom,
    ...trLocale,
  },
  th: {
    ...th
  },
  vi: {
    ...vi
  },
  ko: {
    ...ko
  },
  ja: {
    ...ja
  },
  ms: {
    ...ms
  }
};

export const Lans = [
  // {
  //   name: "Indonesian",
  //   value: "idn"
  // },
  // {
  //   name: '한국어',
  //   value: 'ko'
  // },
  {
    name: "English",
    value: "en"
  }, {
    name: "Tiếng Việt",
    value: "vi"
  }, 
  // {
  //   name: '日本語',
  //   value: 'ja'
  // }, {
  //   name: "แบบไทย",
  //   value: "th"
  // }, {
  //   name: "Melayu",
  //   value: "ms"
  // }
  ]
export function getLanguage() {
  // 从cookis 里面取语言
  const chooseLanguage = Cookies.get("language");
  //  if (!chooseLanguage) {
  //   Cookies.set("language",verConfig.lan || "en");
  //   localStorage.setItem('language', verConfig.lan || "en")
  // }
  return chooseLanguage || verConfig.lan || "ko"
}
const i18n = new VueI18n({
  locale: getLanguage(),
  messages,
});

export default i18n;
