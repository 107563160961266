import verConfig, { ver as _ver_ } from "@tt/config";
export const ver = _ver_;

export const verData = {
  "A6421": {
    tabbar: {
      background: "#fff",
      selectedColor: "#0b93ae",
      color: "#fff",
      type: "image",
      pages: [{
        text: 'newTab.home',
        pagePath: 'goods',
        component: () => import("@/view/goods"),
        iconPath: require("@/assets/tabbar/home.png"),
        selectedIconPath: require("@/assets/tabbar/home_active.png"),
        isSpecial: false,
      }, {
        text: `${'newTab.draw'}`,
        pagePath: 'draw',
        component: () => import('@/view/new/draw'),
        iconPath: require("@/assets/tabbar/activity.png"),
        selectedIconPath: require("@/assets/tabbar/activity_active.png"),
        isSpecial: false,
      }, {
        text: `${'newTab.hall'}`,
        pagePath: 'Merchants',
        component: () => import('@/view/new/merchants'),
        iconPath: require("@/assets/tabbar/datin.png"),
        selectedIconPath: require("@/assets/tabbar/datin_active.png"),
      },
      //  {
      //   text: `newTab.customerService`,
      //   pagePath: 'activity',
      //   component: () => import('@/view/new/activity'),
      //   iconPath: require("@/assets/tabbar/cs.svg"),
      //   selectedIconPath: require("@/assets/tabbar/cs.svg"),
      //   isSpecial: false,
      // },
      {
        text: `${'newTab.mine'}`,
        pagePath: 'user',
        component: () => import('@/view/user'),
        iconPath: require("@/assets/tabbar/mine.png"),
        selectedIconPath: require("@/assets/tabbar/mine_active.png"),
        isSpecial: false,
      }]
    }
  },
}

export default {
  ...verConfig, ...verData[ver]
};